<template>
	<form @submit.prevent="saveItem">
		<b-card title="Guide EFIN">
			<b-row class="mb-2">
				<b-col cols="12" md="6">
					<div class="form-group">
						<v-select
							v-model="filter.group_uuid"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							label="name"
							placeholder="-- Pilih Guide --"
							:options="group"
							:reduce="group => group.uuid"
						/>
					</div>
				</b-col>
			</b-row>
			<b-col cols="12" class="p-0">
				<template v-if="isLoading">
					<b-spinner
						class="mb-1"
						variant="primary"
					/>
					<br>
						Loading...
				</template>
				<template v-else>
					<b-row>
						<b-col cols="12" v-for="(item , index) in inputGuide" :key="index">
							<input type="hidden" v-model="item.uuid">
							<div class="form-group form-row">
								<label class="col-12 col-md-4 col-form-label">Judul</label>
								<div class="col">
									<input 
										type="text" 
										class="form-control"
										v-model="item.title"
									>
								</div>
							</div>
							<div class="form-group form-row">
								<label class="col-12 col-md-4 col-form-label">Deskripsi</label>
								<div class="col">
									<textarea 
										class="form-control"
										v-model="item.description"
									/>
								</div>
							</div>
							<div class="form-group form-row" v-if="item.image_is_mandatory">
								<label class="col-12 col-md-4 col-form-label">Image</label>
								<div class="col">
									<b-form-file
										placeholder="Choose a file or drop it here..."
										drop-placeholder="Drop file here..."
										@change="changePhoto($event, index)"
									/>
									<p class="images__hint"><i>Image Size: 200 x 200 pixel</i></p>
									<div
										v-if="inputGuide[index].image_url"
										class="mt-1 relative"
									>
										<input type="hidden" :value="testing">
										<img
											:src="inputGuide[index].image_guide"
											height="300" width="400"
										>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</template>
			</b-col>
		</b-card>
		<b-col cols="12" class="p-0 mt-2">
		<template v-if="isLoading">
			<button
				variant="primary"
				class="btn waves-effect waves-float waves-light btn-primary"
				disabled
			>
				<b-spinner class="align-middle"></b-spinner>
				<strong>Loading...</strong>
			</button>
		</template>
		<template v-else>
			<button
				variant="primary"
				class="btn waves-effect waves-float waves-light btn-primary"
			>
				Save
			</button>
		</template>
		</b-col>
	</form>
</template>

<script>
import 
	{ 
		BCard, 
		BCardText, 
		BForm, 
		BButton ,
		BCol,
		BRow,
		BSpinner,
		BFormFile,
	} from 'bootstrap-vue'
import { successNotification, errorNotification } from '@/auth/utils'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
	components: {
		BCard,
		BCardText,
		BForm,
		BButton,
		BCol,
		BRow,
		BSpinner,
		vSelect,
		BFormFile,
	},

	data() {
		return {
			filter: {
				group_uuid: ''
			},
			inputGuide: [],
			// image: '',
			group: [],
			testing: null,
			groupSelected: [],
			isLoading: false,
		}
	},

	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	mounted() {
		this.loadGroupList()
	},

	methods: {
		loadGroupList() {
			this.$http.get('/admin/groups/efin-guide').then(response => {
				this.group = response.data.data.data
				response.data.data.data.forEach(element => {
					if (element.name == 'Informasi EFIN') {
						this.groupSelected.push(element) 
					}
					// this.group.push({'uuid':element.uuid, 'name':element.name})
				});
				this.filter.group_uuid = this.groupSelected[0].uuid
			})
		},

		changePhoto(event ,index) {
			console.log(event, index);
			this.inputGuide[index].image_url = event.target.files[0]
			this.inputGuide[index].image = this.inputGuide[index].image_url
			this.showPhoto(event.target.files[0], index)
		},

		showPhoto(file ,index) {
			const reader = new FileReader()
			reader.onload = e => {
				this.testing = e.target.result
				this.inputGuide[index].image_guide = e.target.result
			}
			reader.readAsDataURL(file)
		},

		getData() { 
			this.isLoading = true
			const queryParams = this.filter	
			this.$http.get('admin/guide/efin', {
				params: queryParams,
			}).then(response => {
				const permissionData = response.data.data
				this.listGuide = permissionData
				this.inputGuide = this.listGuide
				for (let index = 0; index < permissionData.length; index++) {
					const element = permissionData[index];
					this.inputGuide[index].image_guide = element.image_url
				}
				this.isLoading = false
			})
		},
		saveItem() {
			this.isLoading = true
			const payload = new FormData()
			if (this.inputGuide !== null) {
				this.inputGuide.forEach((guides, index) => {
					Object.keys(guides).forEach(key => {
						console.log(guides);
						if (key != 'image_guide' && key != 'image_url') {
							payload.append('guides' + '['+ index + '][' + key + ']', guides[key]);
						}
					});
				});
			}
			this.$http.post('admin/guide/efin/batch-update', payload , {
				headers: { 'Content-Type': 'multipart/form-data' },
			}).then(response => {
				successNotification(this, 'Success', 'Guide App Berhasil diedit!')
				this.isLoading = false
			}).catch(error => {
				
			})
		}

	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.images__hint {
	font-size: 10px;
	margin-bottom: 0;
}
</style>>
	
</style>
